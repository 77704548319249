import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import SearchHeader from '@fingo/lib/components/headers/SearchHeader';
import FingoDataGrid from '@fingo/lib/components/dataGrids/FingoDataGrid';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import CompanyBalancesActions from './actions/CompanyBalancesActions';
import { COMPANY_BALANCES } from '../graphql/company-balances/queries';
import { COLUMNS } from '../constants/company-balances';

const CompanyBalances = () => {
  const country = useGetCountryFromUrl();
  const [globalFilter, setGlobalFilter] = useState();
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  return (
    <FingoMainView
      id="company-surplus-balances"
      query={COMPANY_BALANCES}
      queryCustomVariables={{
        globalFilter: globalFilter?.globalFilter,
        countryId: country.id,
      }}
      slots={{
        header: SearchHeader,
        actions: CompanyBalancesActions,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: { title: 'Excedentes', setHeaderFilters: setGlobalFilter },
        actions: { selectedCompanyId: selectedCompanyId?.[0] },
        table: {
          columns: COLUMNS,
          disableSelectionOnClick: true,
          checkboxSelection: true,
          onSelectionModelChange: (ids) => {
            setSelectedCompanyId(ids.slice(-1));
          },
          selectionModel: selectedCompanyId,
          noRowsMessage: () => (
            <Typography variant="body2">No hay balances de empresas</Typography>
          ),
          density: 'compact',
          initialOrderBy: 'id',
          paginationMode: 'server',
          rowsPerPageOptions: [15, 25, 50, 100],
        },
      }}
    />
  );
};

export default CompanyBalances;
